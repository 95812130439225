import React from "react"
import styled from "styled-components"

import { Link as LinkTo } from "gatsby"

import { maxD, minD } from "../../assets/styles/helpers/devices"

import { MainTitle, SecondaryTitle } from "../global/common/text"
import { GrayContainer, LineWrapper } from "../global/common/containers"
import { EqualBox, EqualBoxes } from "../case-study/common/boxes"

import SiteLanguage from "../../helpers/siteLanguage"

const Offer = styled(EqualBox)`
  padding: 0;

  transition: border 200ms ease;

  @media ${minD.tablet} {
    width: 50%;
  }

  &:hover {
    border: 1px solid var(--dark-gray);
  }
`

const OfferLink = styled(LinkTo)`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  padding: 3rem;

  transition: background-color 200ms ease, border 200ms ease, color 200ms ease;

  &:hover {
    background-color: var(--dark-gray);
    color: #fff;
  }

  @media ${maxD.tabletL} {
    padding: 1.7rem;
  }
`

const OfferIcon = styled.div`
  svg {
    width: 55px;
    height: 55px;
    flex-shrink: 0;

    fill: var(--main-green);

    ${OfferLink}:hover > & {
      fill: var(--orange);
    }

    @media ${maxD.mobileL} {
      width: 40px;
      height: 40px;
    }
  }
`

const OfferContent = styled.div`
  padding-left: 40px;

  @media ${maxD.tabletL} {
    padding-left: 25px;
  }
`

const OfferForm = styled.span`
  padding-right: 20px;
`

const JobOffers = ({ data }) => {
  const title = data.wpPage.acfCareers.jobOffersTitle
  const offers = data.allWpJobOffer.nodes

  return (
    <GrayContainer id="open-positions">
      <LineWrapper
        style={{
          paddingTop: "calc(var(--section-spacing) * 0.85)",
          paddingBottom: "calc(var(--section-spacing) * 0.9)",
        }}
      >
        <MainTitle
          dangerouslySetInnerHTML={{ __html: title }}
          style={{
            "--padding-bottom": "calc(var(--section-spacing) * 0.5)",
            maxWidth: "800px",
          }}
        />
        <EqualBoxes
          style={{
            "--dots-appearance": "none",
            paddingBottom: "0",
          }}
        >
          {offers &&
            offers.map((offer, i) => {
              const id = offer.id
              const url = `/${
                SiteLanguage() === "en" ? "job-offer" : "oferta-pracy"
              }/${offer.slug}`

              const name = offer.acfSingleJobOffer.jobOfferName
              const location = offer.acfSingleJobOffer.jobOfferLocation
              const form = offer.acfSingleJobOffer.jobOfferForm

              const icon = offer.acfSingleJobOffer.jobOfferIcon

              return (
                <Offer
                  key={`${id}-${i}`}
                  style={{
                    width: "100%",
                  }}
                >
                  <OfferLink to={url}>
                    <OfferIcon dangerouslySetInnerHTML={{ __html: icon }} />
                    <OfferContent>
                      <SecondaryTitle
                        dangerouslySetInnerHTML={{ __html: name }}
                        style={{
                          lineHeight: "1.3",
                          paddingBottom: "10px",
                        }}
                      />
                      <p>
                        <OfferForm dangerouslySetInnerHTML={{ __html: form }} />
                        <span dangerouslySetInnerHTML={{ __html: location }} />
                      </p>
                    </OfferContent>
                  </OfferLink>
                </Offer>
              )
            })}
        </EqualBoxes>
      </LineWrapper>
    </GrayContainer>
  )
}

export default JobOffers
