import React from "react"
import styled from "styled-components"

import { maxD } from "../../../assets/styles/helpers/devices"

import { GreenBox } from "./common"

const GreenBoxOnTheLeftSide = styled(GreenBox)`
  top: -120px;
  left: -100px;

  --green-box-width: 440px;
  --green-box-height: 550px;

  @media ${maxD.laptop} {
    --green-box-width: min(350px, 100vw);
  }

  @media ${maxD.tablet} {
    left: calc(100% - 210px - 100px);
    transform: translateX(-100%) translateY(calc(-100% + 150px));
  }

  @media ${maxD.mobileL} {
    left: calc(100% - 210px - 50px);
  }
`

const HeaderGreenBoxOnTheLeftSide = () => <GreenBoxOnTheLeftSide />

export default HeaderGreenBoxOnTheLeftSide
