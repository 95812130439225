import React from "react"
import styled from "styled-components"

import { maxD } from "../../assets/styles/helpers/devices"

import Nav from "../global/nav/Nav"
import HeaderContent from "../global/header/Content"
import HeaderImage from "../global/header/Image"
import CareersHeaderIntro from "../global/header/CareersIntro"
import HeaderGreenBoxOnTheLeftSide from "../global/header/GreenBoxOnTheLeftSide"

import {
  BannerContainer,
  LineWrapper,
  LineWrapperWithBottomSpace,
} from "../global/common/containers"
import { SocialMedia } from "../global/SocialMedia"
import { FadedGreenBoxTitle } from "../global/header/common"

import SiteLanguage from "../../helpers/siteLanguage"
import optimizedPath from "../../helpers/optimizedPath"
import getImageBySrcSetAndSize from "../../helpers/getImageBySrcSetAndSize"
import getRangedSrcSet from "../../helpers/getRangedSrcSet"

const CustomBannerContainer = styled(LineWrapper)`
  flex-direction: row-reverse;

  @media ${maxD.tabletL} {
    flex-direction: column;
  }
`

const BannerWrapper = styled(LineWrapperWithBottomSpace)`
  @media ${maxD.tabletL} {
    flex-direction: column;
    margin-top: 80px;
  }
`

const CareersHeader = ({ pageContext, data }) => {
  const title = data.intro

  const image = {
    url: getImageBySrcSetAndSize(data.featuredImage?.srcSet, 'medium') ? getImageBySrcSetAndSize(data.featuredImage?.srcSet, 'medium') : optimizedPath(data.featuredImage?.sourceUrl),
    srcSet: getRangedSrcSet(data.featuredImage?.srcSet, 'medium'),
    alt: (SiteLanguage() !== "en" && data.featuredImage?.atttachement_pl?.altTextPl) ? data.featuredImage?.atttachement_pl?.altTextPl : data.featuredImage?.altText
  }

  const description = {
    big: data.careersIntroBigDescription,
    small: data.careersIntroSmallDescription,
  }

  const link = {
    title: data.introLink.title,
    url: data.introLink.url,
    target: data.introLink.target,
  }

  return (
    <>
      <BannerContainer>
        <Nav pageContext={pageContext} />
        <CustomBannerContainer
          relative
          flex
          spaceBetween
          alignItems="c"
          style={{ zIndex: "9" }}
        >
          <HeaderContent title={title} button={link} />
          <HeaderImage url={image.url} srcSet={image.srcSet} alt={image.alt} />
        </CustomBannerContainer>
      </BannerContainer>
      <BannerWrapper relative flex spaceBetween alignItems="fe">
        <HeaderGreenBoxOnTheLeftSide />
        <div>
          <FadedGreenBoxTitle>
          {
            SiteLanguage() === "en" ?
              "You can also follow us on social media:"
            :
              "Śledź nas w mediach społecznościowych"
          }
          </FadedGreenBoxTitle>
          <SocialMedia />
        </div>
        <CareersHeaderIntro description={description} />
      </BannerWrapper>
    </>
  )
}

export default CareersHeader
