import React from "react"
import styled from "styled-components"

import { betweenD, maxD } from "../../../assets/styles/helpers/devices"

import { Square } from "../common/ornaments"
import { SecondaryTitle } from "../common/text"
import { GalleryItem, GrayBoxInner } from "./common"

const Box = styled(GalleryItem)`
  @media ${maxD.tablet} {
    order: -1;
  }
`

const BoxInner = styled(GrayBoxInner)`
  padding: 40px;
`

const Title = styled(SecondaryTitle)`
  @media ${betweenD.tabletLLaptop} {
    font-size: 1.2rem;
  }
`

const SingleDescription = ({ title }) => (
  <Box>
    <Square as="div">
      <BoxInner>
        <Title dangerouslySetInnerHTML={{ __html: title }} />
      </BoxInner>
    </Square>
  </Box>
)

export default SingleDescription
