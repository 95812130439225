import React from "react"
import styled from "styled-components"

import { minD, maxD } from "../../../assets/styles/helpers/devices"

import { MiddleSpacedBigDescription } from "../common/text"

import { Intro } from "./common"

const CareersIntro = styled(Intro)`
  @media ${minD.tabletL} {
    padding-right: calc(var(--section-spacing) * 0.7);

    margin-left: auto;
    width: 61%;
  }

  @media ${maxD.tabletL} {
    margin: 45px 0 0;
  }
`

const CareersHeaderIntro = ({ description }) => {
  return (
    <CareersIntro>
      <MiddleSpacedBigDescription
        dangerouslySetInnerHTML={{ __html: description.big }}
      />
      <p dangerouslySetInnerHTML={{ __html: description.small }}></p>
    </CareersIntro>
  )
}

export default CareersHeaderIntro
