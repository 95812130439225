import React from "react"
import List from "../global/List"

const Reasons = ({ data }) => {
  const title = data.careersReasonsTitle

  const list = data.careersReasonsList

  return (
    <List
      title={title}
      list={list}
      titleStyle={{
        maxWidth: "100%",
        "--padding-bottom": "4.5rem",
      }}
    />
  )
}

export default Reasons
