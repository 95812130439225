import React from "react"
import styled from "styled-components"

import { minD, maxD } from "../../assets/styles/helpers/devices"

import { CenterTitle } from "./common/text"
import { Button } from "./atoms/Button"
import { GrayContainer, LineWrapper } from "./common/containers"
import { UnstyledList } from "./common/essentials"

const ListWrapper = styled(LineWrapper)`
  padding: 0 100px var(--section-spacing) 0;

  @media ${maxD.laptopL} {
    padding: 0 60px var(--section-spacing) 0;
  }

  @media ${maxD.tabletL} {
    padding: 0 60px var(--section-spacing) 0;
  }

  @media ${maxD.mobileL} {
    padding: 0 0 50px;
  }
`

const ListInner = styled(UnstyledList)`
  @media ${minD.tablet} {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  margin: -1px 0 0 -1px;
`

const ListItem = styled.li`
  padding: 40px;
  border: 1px solid var(--border-green);
  margin: 0 -1px -1px 0;

  @media ${minD.tablet} {
    width: 50%;
  }

  @media ${minD.laptopL} {
    width: 33.33%;
  }

  @media ${maxD.tabletL} {
    padding: 30px;
  }
`

const ListItemHeader = styled.header`
  display: flex;
  align-items: center;
`

const ListNumber = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  width: 4.1rem;
  height: 4.1rem;
  margin-right: 1.6rem;

  border-radius: 50%;
  color: var(--main-green);
  font-size: 2.2rem;
  font-weight: 600;
  background-color: var(--faded-green);

  @media ${maxD.tabletL} {
    width: 3.4rem;
    height: 3.4rem;
    font-size: 1.8rem;
  }

  @media ${maxD.mobileL} {
    margin-right: 1rem;
  }
`

const ListTitle = styled.h3`
  font-size: 1.7rem;

  @media ${maxD.tabletL} {
    font-size: 1.5rem;
  }
`

const ListDescription = styled.p`
  margin-top: 1.7rem;

  font-weight: 400;
  font-size: 1.1rem;
  color: var(--paragraph-gray);

  @media ${maxD.tabletL} {
    margin-top: 1.2rem;
  }
`

const ButtonContainer = styled.footer`
  display: flex;
  justify-content: center;

  padding: 4rem 0 0;

  @media ${maxD.desktop} {
    padding: 3rem 0 0;
  }

  @media ${maxD.mobileL} {
    padding: 2.2rem 0 0;
  }
`

const List = ({ title, list, button, containerStyle, titleStyle }) => (
  <GrayContainer style={containerStyle}>
    <ListWrapper>
      <CenterTitle
        style={titleStyle}
        dangerouslySetInnerHTML={{ __html: title }}
      ></CenterTitle>
      <ListInner>
        {list &&
          list.map((item, i) => (
            <ListItem key={`numbers-list-item-${i}`}>
              <ListItemHeader>
                <ListNumber>{i + 1}</ListNumber>
                <ListTitle>{item.header}</ListTitle>
              </ListItemHeader>
              {item.description && (
                <ListDescription dangerouslySetInnerHTML={{ __html: item.description }} />
              )}
            </ListItem>
          ))}
      </ListInner>
      {button && button.url && (
        <ButtonContainer>
          <Button to={button.url} target={button.target} theme="orange">
            {button.title}
          </Button>
        </ButtonContainer>
      )}
    </ListWrapper>
  </GrayContainer>
)

export default List
