import React, { useEffect, useRef } from "react"
import styled, { createGlobalStyle, css } from "styled-components"

import "swiper/scss"

import SwiperCore, { Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

import { maxD } from "../../../assets/styles/helpers/devices"

import GlobalSwiperStyles from "../../../assets/styles/components/Swiper"
import LightboxCloseButton from "./CloseButton"
import { LightboxContainer } from "./common"

import SiteLanguage from "../../../helpers/siteLanguage"
import optimizedPath from "../../../helpers/optimizedPath"
import getImageBySrcSetAndSize from "../../../helpers/getImageBySrcSetAndSize"
import getRangedSrcSet from "../../../helpers/getRangedSrcSet"

SwiperCore.use([Navigation])

const CustomSwiperStyles = createGlobalStyle`
  .swiper-container-images {
    position: static;
  }
`

const innerSwiperStyles = css`
  .swiper-button-prev,
  .swiper-button-next {
    --arrow-horizontal-space: calc(
      var(--slide-horizontal-space) + var(--arrow-box-size)
    );
    bottom: calc(var(--arrow-box-size) * -0.5);
  }

  .swiper-button-prev {
    left: var(--arrow-box-size);
  }

  .swiper-button-next {
    right: var(--arrow-box-size);
  }
`

const ImagesLightboxContainer = styled(LightboxContainer)`
  ${innerSwiperStyles}

  --slide-vertical-space: 100px;
  --slide-horizontal-space: calc(var(--arrow-box-size) + 40px + 10vw);

  position: fixed;
  width: 100%;
  height: 100%;

  top: 0;
  left: 0;
  z-index: var(--max-z-index);

  background-color: #fff;

  @media ${maxD.laptopL} {
    --slide-horizontal-space: calc(var(--arrow-box-size) + 40px + 5vw);
  }

  @media ${maxD.laptop} {
    --slide-vertical-space: 80px;
    --slide-horizontal-space: calc(var(--arrow-box-size) + 40px);
  }

  @media ${maxD.tablet} {
    --slide-vertical-space: 80px;
    --slide-horizontal-space: min(var(--arrow-box-size), 7vw);
  }
`

const SwiperContainer = styled.div`
  position: relative;
  z-index: var(--max-z-index);

  margin: var(--slide-vertical-space) var(--slide-horizontal-space);
`

const LightboxImage = styled.img`
  width: auto;
  height: calc(100vh - var(--slide-vertical-space) * 2);

  isolation: isolate;
  z-index: var(--max-z-index);
`

const ImagesLightbox = ({
  slides,
  currentSlide,
  isLightboxVisible,
  setLightboxVisibility,
}) => {
  const swiper = useRef(null)

  useEffect(() => {
    swiper.current.swiper.slideTo(currentSlide, false, false)
  }, [currentSlide])

  const handleClose = () => {
    setLightboxVisibility(false)
  }

  return (
    <ImagesLightboxContainer className={isLightboxVisible && "visible"}>
      <GlobalSwiperStyles />
      <CustomSwiperStyles />
      <SwiperContainer>
        <Swiper
          navigation
          autoHeight="true"
          ref={swiper}
          className="swiper-container-images"
        >
          {slides &&
            slides.map(slide => {
              const id = slide.id

              const image = {
                url: getImageBySrcSetAndSize(slide?.srcSet, 'medium') ? getImageBySrcSetAndSize(slide?.srcSet, 'medium') : optimizedPath(slide?.sourceUrl),
                srcSet: getRangedSrcSet(slide?.srcSet, 'medium'),
                alt: (SiteLanguage() !== "en" && slide?.atttachement_pl?.altTextPl) ? slide?.atttachement_pl?.altTextPl : slide.aboutGalleryImage?.altText,
              }

              return (
                <SwiperSlide key={`big-${id}`}>
                  <LightboxImage
                    src={image.url}
                    srcSet={image.srcSet}
                    alt={image.alt}
                    placeholder="none"
                    backgroundColor="#fff"
                    imgStyle={{ objectFit: "contain" }}
                  />
                </SwiperSlide>
              )
            })}
        </Swiper>
      </SwiperContainer>
      <LightboxCloseButton onClick={handleClose} />
    </ImagesLightboxContainer>
  )
}

export default ImagesLightbox
